

































import { Component } from 'vue-property-decorator';

import BaseSettingsPage from './BaseSettingsPage.vue';
import { Stepper, Step } from '@/components/stepper';
import {
  InvoiceNumbers,
  LedgerNumbers,
  VatCodes,
  CompanySepa,
  PaymentMethods,
  TermsAndConditions,
} from '@/components/settings';

@Component({
  components: {
    Stepper,
    Step,
    InvoiceNumbers,
    LedgerNumbers,
    VatCodes,
    CompanySepa,
    PaymentMethods,
    TermsAndConditions,
  },
})
export default class GeneralSettingsPage extends BaseSettingsPage {}
